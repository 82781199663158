import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "gatsby";
import { NewsblockContainer, Newsblock } from '../components/Newsblock';
import { CarouselComponent, CarouselComponentSlide } from '../components/carousel';

import "../../scss/main.scss";

export default function BlogPost() {
	return (
		<p>
			This page has been depreciated.
			Please edit the file in templates/blog-post
			you can an example of this page at localhost:8000/blog/test-post or marketing.dev.edyn.care/blog/test-post
		</p>
	)
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				<link
					rel="shortcut icon"
					type="image/svg"
					href="./assets/marketing-site/images/favicon.svg"
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Blog Post</title>
			</Helmet>
			<div className="container">
				<Header />
				<main>
					<div className="blog-post-landing">
						<StaticImage placeholder="blurred"
							className="blog-post-left"
							src="../../static/assets/marketing-site/images/cta-pattern-01.png"
							objectFit="contain"
							placeholder= "blurred"
							loading="eager"
						/>
						<div className="landing-content">
							<h1>Stories and news from the Edyn team and customers</h1>
							<p>09 Oct 2020</p>
						</div>
						<StaticImage placeholder="blurred"
							className="blog-post-right"
							src="../../static/assets/marketing-site/images/cta-pattern-02.png"
							objectFit="contain"
							placeholder= "blurred"
							loading="eager"
						/>
					</div>

					<section className="blog-post-context-section">
						<div className="blog-post-entry">
							<div className="blog-post-entry-img">
								<StaticImage placeholder="blurred"
									src="../../static/assets/marketing-site/images/blog-post.jpg"
									alt="Blog post image"
									placeholder= "blurred"
									loading="eager"
								/>
							</div>
							<div className="blog-post-entry-content">
								<h3 className="secondary">
									Deciding as a family whether live-in care is the right option
									for a loved one is not always easy. You may not have consensus
									on what the best way forward is, or not know which option your
									loved one would prefer. After all, care and support is not
									something we arrange every day!
								</h3>
							</div>
						</div>

						<div className="blog-post-cta">
							<div className="questions-block">
								<h2>
									Many questions can come up when considering what is best,
									these may be things like:
								</h2>
								<ul>
									<li>
										<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/1-blogpostList.svg"
											className="icon-default"
											objectFit="contain"
										/>
										<p>Is a care home or live-in care best?</p>
									</li>
									<li>
										<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/2-blogpostList.svg"
											className="icon-default"
											objectFit="contain"
										/>
										<p>How will they cope with a live-in carer?</p>
									</li>
									<li>
									<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/3-blogpostList.svg"
											className="icon-default"
											objectFit="contain"
										/>
										<p>What sort of help do they need day to day?</p>
									</li>
									<li>
									<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/4-blogpostList.svg"
											className="icon-default"
											objectFit="contain"
										/>
										<p>Could a live-in carer look after them sufficiently, or are their needs too complex?</p>
									</li>
									<li>
									<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/5-blogpostList.svg"
											className="icon-default"
											objectFit="contain"
										/>
										<p>How can we get the right level of care, but also not pay for too much support?</p>
									</li>
								</ul>
							</div>
							<Link className="button-link" to="">
								<button className="btn-primary">
									Book in a care consultation
								</button>
							</Link>
						</div>

						<div className="blog-post-content">
							<p className="primary">
								Live-in care can be a fantastic solution, and in most cases is a
								completely viable alternative to going into a care home. You
								receive one to-one-care from a trained and experienced carer,
								and above all are able to remain in your own home and stay
								connected in your community.
							</p>
							<p className="primary">
								There is so much information out there around care at home and
								often this research creates more questions than answers and when
								you have multiple family members involved in a decision, you all
								only want what's best.
							</p>
							<p className="primary">
								At edyn care we understand how important it is to understand the
								options as a family, and have your questions answered in an
								objective way, which is why we offer family calls. These family
								calls are all virtual, meaning no matter where you all are you
								are able to join. One of our trained care advisors will be able
								to assist with any questions you have about live-in care
								specifically, or more broadly, explaining how live-in care
								works, right through to financing live-in care.
							</p>
						</div>

						<div className="blog-post-social">
							<Link to="https://www.facebook.com/EdynCare/">
								<StaticImage placeholder="blurred"
									className="social-icon"
									src="../../static/assets/marketing-site/images/icons/facebook.svg"
									alt="facebook"
									objectFit="contain"
								/>
							</Link>
							<Link to="https://twitter.com/EdynCare">
								<StaticImage placeholder="blurred"
									className="social-icon"
									src="../../static/assets/marketing-site/images/icons/twitter.svg"
									alt="twitter"
									objectFit="contain"
								/>
							</Link>
							<Link to="https://www.linkedin.com/company/edyn-care/">
								<StaticImage placeholder="blurred"
									className="social-icon"
									src="../../static/assets/marketing-site/images/icons/linkedin.svg"
									alt="linkedin"
									objectFit="contain"
								/>
							</Link>
							<Link to="mailto:hello@edyn.care">
								<StaticImage placeholder="blurred"
									className="social-icon"
									src="../../static/assets/marketing-site/images/icons/mail.svg"
									alt="mail"
									objectFit="contain"
								/>
							</Link>
						</div>

						<div className="blog-post-author">
							<StaticImage placeholder="blurred"
								className="author-background-left author-background"
								src="../../static/assets/marketing-site/images/author-background-2.png"
								alt="background"
								objectFit="contain"

							/>
							<div className="author-details">
								<StaticImage placeholder="blurred"
									src="../../static/assets/marketing-site/images/blog-post-author.png"
									alt="Blog Post
										Author"
										objectFit="contain"

								/>
								<h2>Shaun Louis</h2>
								<p>Family Care Advisor</p>
							</div>
							<StaticImage placeholder="blurred"
								className="author-background-right author-background"
								src="../../static/assets/marketing-site/images/author-background-1.png"
								alt="background"
								objectFit="contain"

							/>
						</div>
					</section>
					<hr className="blog-post-line" />

					<NewsblockContainer title="Related posts" className="newsblock-desktop newsblock-latest">
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="What are the qualities of a good carer"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-5.svg" />
						</Newsblock>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="How do you know when you need care?"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-4.svg" />
						</Newsblock>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="What are the options for elderly care?"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-3.svg" />
						</Newsblock>
					</NewsblockContainer>


					<NewsblockContainer title="Related posts" className="newsblock-mobile">
					<CarouselComponent>
						<CarouselComponentSlide>
							<Newsblock
								color="yellow"
								date="'{DD MM}'"
								title="What are the qualities of a good carer"
								description="I represent Edyn Care by making a remarkable difference
										in the journey of caring and supporting for our lovely
										clients."
								link="#">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-5.svg" />
							</Newsblock>
						</CarouselComponentSlide>
						<CarouselComponentSlide>
							<Newsblock
								color="yellow"
								date="'{DD MM}'"
								title="How do you know when you need care?"
								description="I represent Edyn Care by making a remarkable difference
										in the journey of caring and supporting for our lovely
										clients."
								link="#">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-4.svg" />
							</Newsblock>
						</CarouselComponentSlide>
						<CarouselComponentSlide>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="What are the options for elderly care?"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-3.svg" />
						</Newsblock>
						</CarouselComponentSlide>
						</CarouselComponent>
					</NewsblockContainer>
				</main>
			</div>
			<Footer />
		</>
	);
}
